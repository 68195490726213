import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

function SimplePopup({ open, onClose, content }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <div style={{ whiteSpace: "pre-wrap" }}>{content}</div>
      </DialogContent>
    </Dialog>
  );
}

SimplePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
};

export default SimplePopup;
