/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import MKTypography from "../../../components/MKTypography";

function Information() {
  return (
    <MKBox
      component="section"
      py={6}
      my={6}
      sx={{
        backgroundColor: "#e9e9f2",
      }}
    >
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center", fontFamily: "'Playfair Display', serif" }}
        >
          <MKTypography
            variant="h3"
            fontWeight="bold"
            mb={4}
            sx={{
              fontFamily: "'Playfair Display', serif",
            }}
          >
            Meine Dienstleistungen
          </MKTypography>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ ml: "auto" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                icon="computer"
                title="Entwicklung maßgeschneiderter Anwendungen"
                description="Von der Konzeption bis zur Implementierung, mit Fokus auf Performance und Skalierbarkeit.
                Profitieren Sie von einer Software, die nicht nur technisch ausgereift ist, sondern auch strategisch an Ihre Geschäftsziele angepasst wird, um Ihren langfristigen Erfolg zu sichern."
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                icon="bolt"
                title="Optimierung bestehender Systeme"
                description="Verbesserung der Funktionalität und Effizienz Ihrer Software, sowohl in On-Premise- als auch in Cloud-Umgebungen.
                Mit meiner Unterstützung können Sie die Betriebskosten senken, Ausfallzeiten minimieren und die Benutzerzufriedenheit erhöhen."
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                icon="insights"
                title="Datenanalyse und Insights"
                description="Meine Expertise in Datenanalyse ermöglicht es Ihnen, aus Ihren Daten aussagekräftige Einblicke zu gewinnen.
                Ich unterstütze Sie dabei, Muster zu erkennen, Prognosen zu erstellen und fundierte Entscheidungen zu treffen, die auf präzisen Datenanalysen basieren."
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                icon="loop"
                title="Automatisierungslösungen"
                description="Durch die Implementierung von Automatisierung in
                Ihrem täglichen Betrieb können Sie nicht nur wertvolle Arbeitszeit sparen, sondern auch die Genauigkeit
                und Effizienz Ihrer Abläufe erhöhen, sodass Sie sich auf die wirklich wichtigen Aspekte Ihres Geschäfts konzentrieren können."
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
