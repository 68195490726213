/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={8} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Interesse geweckt?
                    <br />
                  </>
                }
                description={
                  <>
                    Schreiben Sie mir eine Email an{" "}
                    <span style={{ fontWeight: "bold", color: "white" }}>
                      info@florianplatau.de
                    </span>
                  </>
                }
              />
              <RotatingCardBack
                image={bgBack}
                title="Kontaktieren Sie mich!"
                description="Klicken Sie hier, um eine E-Mail zu senden!"
                action={{
                  type: "external",
                  route: "mailto:info@florianplatau.de",
                  label: "E-Mail senden",
                }}
              />
            </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
