/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples

// Images
import mercedes from "assets/images/logos/gray-logos/Mercedes-Logo.svg.png";
import porsche from "assets/images/logos/gray-logos/porsche.png";
import trumpf from "assets/images/logos/gray-logos/Logo_Trumpf.svg.png";
import schwarz from "assets/images/logos/gray-logos/schwarz.png";
import MKBadge from "../../../components/MKBadge";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={8}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Erfahrungen"
            container
            sx={{ mb: 4 }}
          />
          <MKTypography
            variant="h3"
            fontWeight="bold"
            mb={2}
            sx={{
              fontFamily: "'Playfair Display', serif",
            }}
          >
            Erfahrung aus verschiedenen Branchen
          </MKTypography>
          <MKTypography variant="body2" color="text">
            Ich hatte die Gelegenheit, erfolgreich mit renommierten Unternehmen zusammenzuarbeiten,
            was mir einzigartige Einblicke in unterschiedlichsten Strukturen gewährt hat
          </MKTypography>
        </Grid>
        <Grid container spacing={3} justifyContent="center" sx={{ mx: "auto" }}>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={mercedes} alt="Mercedes" maxWidth="50%" />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={porsche} alt="Porsche" maxWidth="40%" />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={trumpf} alt="Trumpf" maxWidth="50%" />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={schwarz} alt="Schwarz" maxWidth="65%" />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
