/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import { FaArrowDown } from "react-icons/fa";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";
// Material Kit 2 React examples

// Presentation page sections
import UeberMich from "pages/Presentation/sections/Ueber Mich";
import Information from "pages/Presentation/sections/Information";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Contact from "pages/Presentation/sections/Contact";
import SimplePopup from "pages/Presentation/sections/SimplePopup";

// Images
import bgImage from "assets/images/bg-presentation.jpg";

import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Presentation() {
  const [impressumOpen, setImpressumOpen] = useState(false);
  const [datenschutzOpen, setDatenschutzOpen] = useState(false);

  const handleImpressumClick = () => {
    setImpressumOpen(true);
  };

  const handleDatenschutzClick = () => {
    setDatenschutzOpen(true);
  };

  const handleClose = () => {
    setImpressumOpen(false);
    setDatenschutzOpen(false);
  };

  const scrollRef = useRef(null);

  const scrollToBottom = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const impressumContent = `
     Impressum
     ------------
    Angaben gemäß § 5 TMG:
    Florian Platau (Freiberufler)
    Glehner Str 6
    41352 Korschenbroich
    
    Kontakt:
    E-Mail: info@florianplatau.de
    
    Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
    Florian Platau
    
    Haftungsausschluss:

    Haftung für Inhalte
    
    Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    
    Haftung für Links
    
    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
    
    Urheberrecht
    
    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
    
    Datenschutz
    
    Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.

   `;

  const datenschutzContent = `
    Datenschutzerklärung
     ------------
    Name und Kontaktdaten des Verantwortlichen:
    Folgende Stelle verantwortlich gem. Art. 4 Nr. 7 Datenschutz-Grundverordnung (DSGVO) (fortan: „Verantwortlicher“):
    
    Florian Platau, Glehner Str 6, 41352 Korschenbroich
    
     
    I. Datenverarbeitung über die Website, E-Mail und andere Kommunikationsmittel
    Umfang der Verarbeitung
    
    Der Verantwortliche ist per Post, E-Mail, ggf. Messenger, Kontaktformular, Telefon oder über Soziale Netzwerke für Ihre Anfragen erreichbar. Einfache Anfragen, die keine Identifizierung Ihrer Person erforderlich machen, können anonym erfolgen. Soweit Ihre Identifizierung erforderlich sein sollte, z.B. um Ihnen zu antworten oder Sie zurückzurufen, erhebt der Verantwortliche Ihre Kontaktdaten.
    
    Wenn Sie über das Kontaktformular des Verantwortlichen eine Nachricht schreiben, erfasst er die von Ihnen eingegebenen personenbezogenen Daten (Vorname, Nachname, E-Mail-Adresse, Nachrichteninhalt). Darüber hinaus erfassen erfasst er Ihre IP-Adresse und Logfiles über das Datum und die Uhrzeit des Nachrichtenversands.
    
    Zweck der Verarbeitung
    
    Ihre personenbezogenen Daten werden verarbeitet, um Sie zu identifizieren, um Ihre Nachricht ggf. einem bestehenden Vertrag, einer Stellenanzeige, einem Bewerbungsverfahren oder einer sonstigen geschäftlichen Beziehung zuzuordnen, zu speichern, zu beantworten oder ggf. weiterzuleiten.
    
    Rechtsgrundlage der Verarbeitung
    
    Falls Sie dem Verantwortlichen anlässlich der Korrespondenz mit Ihnen eine Einwilligung erteilt haben, z.B. im Rahmen des Kontaktformulars, darf er Ihre Daten im Rahmen Ihrer Einwilligung gem. Art. 6 Abs. 1 S. 1 lit a DSGVO verarbeiten.
    
    Die Verarbeitung Ihrer Daten kann im Einzelfall für die Erfüllung eines Vertrags, dessen Vertragspartei Sie sind, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich sein, die auf Ihre Anfrage erfolgen, Art. 6 Abs. 1 S. 1 lit. b DSGVO.
    
    Die Verarbeitung der personenbezogenen Daten kann auch aufgrund der berechtigten Interessen des Verantwortlichen gem. Art. 6 Abs. 1 S. 1 lit. f DSGVO erfolgen.
    
    Berechtigte Interessen
    
    Der Verantwortliche hat ein berechtigtes wirtschaftliches Interesse daran, über seine Kontaktformulare und (elektronische) Kommunikationsmittel für die Bearbeitung und Beantwortung von Anfragen mit Interesse an seinen Produkten erreichbar zu sein und auf Ihre Anfragen zu antworten. Zudem hat er ein berechtigtes Interesse, Ihre Daten zu verarbeiten, soweit Sie z.B. Geschäftsführer, Beschäftigter, Stellenbewerber, Kunde, potenzieller Kunde oder sonstiger Vertreter eines Vertragspartners des Verantwortlichen sind. Der Verantwortliche erhebt auch Informationen, um Ihre Stellenbewerbung zu prüfen. Er verarbeitet Ihre Daten zudem zum Zweck der Vertragserfüllung, der Geltendmachung oder der Abwehr von Ansprüchen.
    
    Empfänger oder Kategorien von Empfängern
    
    Ihre personenbezogenen Daten werden in der Regel von dem Verantwortlichen verarbeitet. Dieser gibt Ihre personenbezogenen Daten, die er über elektronische Kommunikationsmittel erhalten hat, an externe Empfänger nur weiter, soweit dies im Einzelfall erforderlich ist, um Ihre Anfrage zu bearbeiten.
    
    Drittstaatenübermittlung
    
    Der Verantwortliche übermittelt Ihre personenbezogenen Daten nicht ins Ausland, es sei denn, Sie sind damit einverstanden.
    
    Dauer der Speicherung
    
    Ihre personenbezogenen Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung und aufgrund vertragsrechtlicher, handels- oder steuerrechtlicher Aufbewahrungspflichten nicht mehr erforderlich sind. Bewerbungsunterlagen werden bis mindestens zwei Monate nach Zugang der Ablehnung aufbewahrt (§ 15 Abs. 4 AGG). Rechnungsbelege werden 10 Jahre, Handelsbriefe 6 Jahre lang aufbewahrt.
    
    Widerspruchs- und Beseitigungsmöglichkeit
    
    Als Nutzer haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 S. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen (Art. 21 Abs. 1 DSGVO).
    
    Soweit der Verantwortliche die Verarbeitung Ihrer Daten auf die von Ihnen erteilte Einwilligung oder auf einen Vertrag stützt, steht Ihnen ein Recht zum Widerspruch nicht zu.
    
    Bereitstellungspflicht
    
    Ihre personenbezogenen Daten wie Anrede, Vorname, Nachname, E-Mail-Adresse sind erforderlich, um die Anfrage über das Kontaktformular an den Verantwortlichen zu übermitteln. Im Übrigen ist die Bereitstellung Ihrer personenbezogenen Daten freiwillig. Im Falle der Nichtbereitstellung Ihrer personenbezogenen Daten kann es ein, dass der Verantwortliche Ihre Anfragen, Anträge oder Wünsche nicht bearbeiten oder beantworten kann. Sollten Sie dem Verantwortlichen im Kontaktformular Ihre E-Mail-Adresse nicht oder unrichtig bereitstellen, kann er Ihnen allerdings nicht antworten.
    
    II. Datenverarbeitung durch Logfiles
    Umfang der Verarbeitung
    
    Bei jedem Aufruf der Websites des Verantwortlichen erfasst dessen System automatisiert Daten und Informationen von dem Computersystem, mit dem Sie als Nutzer die Website des Verantwortlichen aufrufen. Diese Daten werden auf dem Server des Verantwortlichen in einer Protokolldatei (sogenannte Logfiles) gespeichert und verarbeitet. Folgende personenbezogene Daten werden hierbei erhoben:
    
    Logfiles speichern unter anderem die IP-Adresse, den verwendeten Browser, Uhrzeit und Datum und das genutzte System eines Seitenbesuchers. Die IP-Adresse ist eine Zahlenkette, die für den Zeitpunkt des Aufrufs der o.g. Website Ihr Computersystem eindeutig zuordnet. Bei dem Verantwortlichen werden nur anonymisierte IP-Adressen von Besuchern der Website gespeichert. Auf Webserver-Ebene erfolgt dies dadurch, dass im Logfile standardmäßig statt der tatsächlichen IP-Adresse des Besuchers z.B. 123.123.123.123 eine IP-Adresse 123.123.123.XXX gespeichert wird, wobei XXX ein Zufallswert zwischen 1 und 254 ist. Die Herstellung eines Personenbezuges ist nicht mehr möglich.
    
    
    Zweck der Verarbeitung
    
    Die IP-Adresse dient dem Empfang und Versand von Datenpaketen und ermöglicht, dass ein Nutzer eine Website abrufen kann. Die vorübergehende Speicherung der IP-Adresse auf dem Server des Verantwortlichen ist notwendig, um nach dem Aufruf dieser Website die Seiteninhalte an das Computersystem des Nutzers zu übermitteln, damit dieser die Inhalte wahrnehmen kann.
    
    Die Speicherung in Protokolldateien erfolgt, um die Funktionsfähigkeit der Website sicherzustellen und eventuell auftretende Übermittlungsfehler feststellen zu können. Zudem dienen diese Daten dem Verantwortlichen zur Optimierung der Website und zur Sicherstellung der Sicherheit seiner informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt.
    
    
    Rechtsgrundlage der Verarbeitung
    
    Die Verarbeitung erfolgt aufgrund der berechtigten Interessen des Verantwortlichen nach Art. 6 Abs. 1 S. 1 lit. f DSGVO.
    
    Berechtigte Interessen
    
    Der Verantwortliche hat ein berechtigtes Interesse daran, die oben genannten personenbezogenen Daten für die oben genannten Zwecke zu verarbeiten, um zu gewährleisten, dass seine Produkt- und Dienstleistungsinformationen online abrufbar sind.
    
    
    Empfänger oder Kategorien von Empfängern
    
    Ihre personenbezogenen Daten werden an die EDV-Abteilung des Verantwortlichen und an seine Auftragnehmer, die zum Hosting und zur Bereitstellung der IT-Ressourcen für den Betrieb der Website beauftragt sind, sowie an Maisch.law Rechtsanwälte, Neuhauser Str. 15, 80331 München, oder ggf. im Einzelfall an weitere Anwaltskanzleien oder Ermittler sowie ggf. an Justizbehörden weitergegeben.
    
    Drittstaatenübermittlung
    
    Der Verantwortliche übermittelt Ihre personenbezogenen Daten nicht ins Ausland, es sei denn, Sie sind damit einverstanden.
    
    Dauer der Speicherung
    
    Ihre personenbezogenen Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist. Die IP-Adresse des Nutzers muss für die Dauer der Sitzung gespeichert bleiben, um die Nutzung der Website zu ermöglichen.
    
    Im Falle der Speicherung Ihrer Daten in der Protokolldatei werden die darin erhobenen Daten unbefristet gespeichert.
    
    Widerspruchs- und Beseitigungsmöglichkeit
    
    Als Nutzer haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 S. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen (Art. 21 Abs. 1 DSGVO). Der Verantwortliche verarbeitet Ihre personenbezogenen Daten in diesem Fall nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihren Interessen, Rechten und Freiheiten als Nutzer überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung und Abwehr von Rechtsansprüchen.
    
    Die Verarbeitung der personenbezogenen Daten zur Bereitstellung der Website und zur Erstellung der Protokolldatei ist für den Betrieb der Website zwingend erforderlich. Der Nutzer kann daher dieser Art der Verarbeitung nicht widersprechen.
    
    Bereitstellungspflicht
    
    Die Verarbeitung von Logfiles ist erforderlich, um die Website richtig darzustellen. Für den Fall, dass Sie keine IP-Adresse bereitstellen, ist der Seitenabruf aus technischen Gründen ausgeschlossen.
    
    III. Datenverarbeitung durch Cookies
    Umfang der Verarbeitung
    
    Auf seiner Website verwendet der Verantwortliche sogenannte Cookies. Bei Cookies handelt es sich um Textdateien, die auf dem IT-System des Nutzers gespeichert werden, sobald er die Website des Verantwortlichen aufruft. Cookies enthalten charakteristische Zeichenfolgen, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglichen.
    
    Der Verantwortliche setzt Cookies ein, um seine Website nutzerfreundlicher zu gestalten. Einige Seitenelemente der Website machen es erforderlich, dass der aufrufende Internetbrowser auch nach einem Seitenwechsel innerhalb der Website identifiziert werden kann.
    
    Unbedingt erforderliche Cookies
    
    Der Zweck der Verwendung unbedingt technisch notwendiger Cookies ist es, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen der Internetseite des Verantwortlichen können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Internetbrowser auch nach einem Seitenwechsel wiedererkannt wird. Mit diesen technisch notwendigen Cookies werden Daten erhoben, gespeichert und an den Verantwortlichen übermittelt, um den Abruf ihrer Website zu ermöglichen. Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet.
    
    Funktionale Cookies
    
    Funktionale Cookies werden eingesetzt, um personalisierte Daten, wie z.B. Ihre Sprachpräferenz anonymisiert zu speichern und so beim nächsten Mal automatisch wieder aufzurufen. Funktionale Cookies können außerdem dazu genutzt werden, angeforderte Funktionen wie das Abspielen von Videos zu ermöglichen. Die Daten werden nicht an Dritte weitergegeben und verfolgen nicht Ihre Bewegung auf anderen Internetseiten.
    
    Cookies für Analysen und Statistiken
    
    Statistik-Cookies erfassen Informationen pseudonym. Diese Informationen helfen dem Verantwortlichen, zu verstehen, wie seine Besucher die Webseite nutzen, zum Beispiel welche Seiten besonders häufig geklickt werden. Dadurch kann er seine Inhalte und Funktionen an Ihre Interessen anpassen. Bei Deaktivierung können Probleme mit einzelnen Funktionalitäten der Webseite auftreten.
    
    Marketing und sonstige Third-Party-Cookies
    
    Cookies für Marketing und Cookies von Dritten (Third-Party-Cookies), werden von dem Verantwortlichen genutzt, um den Erfolg von Marketingmaßnahmen messen zu können z.B. ob man eine Webseite besucht hat. Sie können auch dazu dienen, diese im Anschluss entsprechend zu steuern z.B. die Häufigkeit einer angezeigten Anzeige zu limitieren. Es ist möglich, dass diese Information mit Dritten, wie z.B. Werbetreibenden, geteilt wird. Häufig werden diese Cookies auch mit Seitenfunktionalitäten von Dritten verbunden.
    
    Zweck der Verarbeitung
    
    Der Zweck der Verwendung technisch notwendiger Cookies ist es, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen der Internetseite des Verantwortlichen können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Internetbrowser auch nach einem Seitenwechsel wiedererkannt wird. Mit diesen technisch notwendigen Cookies werden Daten erhoben, gespeichert und an den Verantwortlichen übermittelt, um den Abruf seiner Website zu ermöglichen. Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet.
    
    Cookies, die technisch nicht notwendig sind, setzt der Verantwortliche ein, um seine Zielgruppen besser kennenzulernen, ihre Interessen auszuwerten und um sie im Wege von Direktmarketing auf seine Produkte und sein Unternehmen aufmerksam zu machen.
    
    Rechtsgrundlage der Verarbeitung
    
    Falls Sie dem Verantwortlichen über den Cookie-Banner für technisch nicht notwendige Cookies eine Einwilligung erteilt haben, darf er Ihre Daten im Rahmen Ihrer Einwilligung gem. Art. 6 Abs. 1 S. 1 lit a DSGVO verarbeiten. Für Cookies, die technisch notwendig sind, um diese Internetseite zu betreiben, erfolgt die Verarbeitung aufgrund der berechtigten Interessen des Verantwortlichen nach Art. 6 Abs. 1 S. 1 lit. f DSGVO.
    
    Berechtigte Interessen
    
    Der Verantwortliche hat ein berechtigtes wirtschaftliches Interesse an der Außendarstellung seines Unternehmens und an der Bewerbung seiner Produkte. Technisch notwendige Cookies helfen dem Verantwortlichen bei der optimalen Darstellung der Website. 
    
    Empfänger oder Kategorien von Empfängern
    
    Die Daten der Cookies werden an internen Stellen des Verantwortlichen und an seine Auftragnehmer, die zum Hosting und zur Bereitstellung der IT-Ressourcen beauftragt sind, weitergegeben.
    
    Drittstaatenübermittlung
    
    Grundsätzlich werden Informationen, die in Cookies gespeichert sind, nicht in Drittstaaten übermittelt, es sei denn, eine Identifizierung der Nutzer für Drittdienstleister ist notwendig.
    
    Dauer der Speicherung
    
    Die personenbezogenen Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Verarbeitung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist. Cookies werden auf dem IT-System des Nutzers gespeichert und von diesem an den Server des Verantwortlichen übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Speicherung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen.
    
    Widerspruchs- und Beseitigungsmöglichkeit
    
    Als Nutzer haben Sie das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 S. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen (Art. 21 Abs. 1 DSGVO). Der Verantwortliche verarbeitet die personenbezogenen Daten in diesem Fall nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten des Nutzers überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung und Abwehr von Rechtsansprüchen. Werden Cookies für die Website des Verantwortlichen deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website, z.B. der Warenkorb des Shops, vollumfänglich genutzt werden. Die Verarbeitung der personenbezogenen Daten zur Bereitstellung der Website durch technisch erforderliche Cookies ist für den Betrieb der Website zwingend erforderlich. Der Nutzer kann daher dieser Art der Verarbeitung nicht widersprechen.
    
    Bereitstellungspflicht
    
    Die Bereitstellung Ihrer Daten zur Verarbeitung von Cookies ist freiwillig. Im Fall der Nichtbereitstellung Ihrer Daten kann es sein, dass der Verantwortliche Ihr IT-System nicht adressieren und Sie seine Website nicht oder nicht vollumfänglich nutzen können.
    
    IV. Datenverarbeitung bei der Kontaktaufnahme über WhatsApp Business App
    a) Umfang der Verarbeitung
    
    Der Verantwortliche verarbeitet personenbezogene Daten über den Messenger-Dienst „WhatsApp Business“ https://business.whatsapp.com/ von WhatsApp Ireland Limited, Attn: Privacy Policy, Merrion Road, Dublin 4, D04 X2K5, Irland. Die Datenschutzerklärung von WhatsApp Ireland Limited ist hier abrufbar:
    
    https://www.whatsapp.com/legal/privacy-policy-eea#privacy-policy-our-global-operations
    
    Nach Angaben des Diensteanbieters erfolgt der Nachrichtenaustausch Ende-zu-Ende-verschlüsselt. https://faq.whatsapp.com/1148840052398648
    
    b) Zweck der Verarbeitung
    
    Der o.g. Anbieter verarbeitet die personenbezogenen zur einfachen und schnellen Kontaktaufnahme und zur Führung von Gesprächen zur Ersteinschätzung eines Falls.
    
    c) Rechtsgrundlagen der Verarbeitung
    
    Falls Sie Nutzer der Dienste des o.g. Anbieter sind, haben Sie diesem Anbieter im Rahmen Ihres Nutzungsvertrags eine Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten für einen oder mehrere bestimmte Zwecke erteilt. In diesem Fall stellt diese Einwilligung die Rechtsgrundlage gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO dar. Der o.g. Anbieter darf zudem Ihre personenbezogenen Daten in dem Umfang verarbeiten, der für die Eingehung und Erfüllung Ihres Nutzervertrags gem. Art. 6 Abs. 1 S. 1 lit. b DSGVO erforderlich ist. 
    
    d) Empfänger oder Kategorien von Empfängern
    
    Ob und in welchem Umfang WhatsApp Ireland Ltd. personenbezogene Daten weitergibt, entnehmen Sie bitte der Datenschutz-Information des Anbieters. Ihre personenbezogenen Daten, die der Verantwortliche im Rahmen seines WhatsApp Business Kontos verarbeitet, werden nur an Rechtsanwälte, Ermittler und Justizbehörden weitergegeben.
    
    Drittstaatenübermittlung
    
    Bei der Nutzung von WhatsApp kann es sein, dass personenbezogene Daten an WhatsApp-Rechenzentren in die USA übermittelt werden. In den USA gibt es kein angemessenes Datenschutzniveau. Das bedeutet, dass die Rechte der Betroffenen, z.B. das Recht auf Auskunft oder Löschung nicht genauso effektiv geltend gemacht werden kann, wie in der EU.
    
    Der Betroffene wird bei der Kontaktaufnahme des Verantwortlichen per WhatsApp auf die Datenschutzrisiken hingewiesen. Indem er den Austausch von Nachrichten per WhatsApp fortführt, erklärt er, dass er diese Umstände zur Kenntnis genommen hat und erklärt sich ausdrücklich damit einverstanden, dass der Verantwortliche seine personenbezogenen Daten an diesen Auftragnehmer übermittelt. Diese Übermittlung wird auf Art. 49 Abs. 1 lit. a DSGVO gestützt.
    
    Darüber hinaus hat der Verantwortliche mit dem Diensteanbieter Standardvertragsklauseln (SCC) vereinbart.
    
    Aus Gründen der Datensparsamkeit empfiehlt der Verantwortliche, dass die Kommunikation per verschlüsselter E-Mail ober über die Messenger Signal oder Threema geführt wird.
    
    Dauer der Speicherung
    
    Die personenbezogenen Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind und keine Aufbewahrungspflichten beachtet werden müssen. Auf die Speicherung Ihrer personenbezogenen Daten durch den genannten Anbieter hat der Verantwortliche keinen Einfluss.
    
     Bereitstellungspflicht
    
    Die Nutzung von WhatsApp Business ist freiwillig. Es steht dem Nutzer frei, den Verantwortlichen auf anderen Kommunikationswegen, z.B. telefonisch, zu erreichen.
    
    V. Datenverarbeitung im Rahmen der Auftragsabwicklung
    Umfang der Verarbeitung
    
    Der Verantwortliche erhebt Namen, Adressdaten, Geburtsdatum, Geburtsort, Staatsangehörigkeit, Titel/Grad, Anrede, Telefonnummer und E-Mail-Adresse, ggf. Bankdaten, ggf. Angaben zu Wallet-Adressen und -IDs, Informationen über Zahlungen und alle vom Geschädigten per E-Mail, per WhatsApp oder über sonstige Kommunikationsmittel übermittelten Angaben.
    
    Empfänger oder Kategorien von Empfängern
    
    Die personenbezogenen Daten werden an die EDV-Abteilung des Verantwortlichen, ggf. an Rechtsanwälte, an Strafverfolgungs- und Justizbehörden, an Wirtschaftsermittler und ggf. IT-Forensiker übermittelt.
    
    Drittstaatenübermittlung
    
    Der Verantwortliche beabsichtigt nicht, Ihre personenbezogenen Daten ins Ausland zu übermitteln, soweit dies nicht zur Erfüllung eines Vertrags erforderlich ist, z.B. um eine Strafanzeige im Ausland zu stellen.
    
    Dauer der Speicherung
    
    Personenbezogene Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Soweit Ihre Daten von Rechtsanwälten verarbeitet werden, sind diese zur Aufbewahrung der Handakte für die Dauer von 6 Jahren verpflichtet.
    
    VI. Betroffenenrechte
    1. Recht auf Widerruf der Einwilligung: Der Geschädigte hat das Recht, gemäß Art. 7 Abs. 3 DSGVO seine einmal erteilte Einwilligung jederzeit gegenüber dem Verantwortlichen zu widerrufen. Dies hat zur Folge, dass die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortgeführt werden darf.
    
    2. Recht auf Auskunft: Der Geschädigte hat gemäß Art. 15 DSGVO das Recht, Auskunft über seine beim Verantwortlichen verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere kann er Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen seine Daten offengelegt wurden oder werden, die geplante Speicherdauer, über das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft seiner Daten, sofern diese nicht beim Verantwortlichen erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen.
    
    3. Recht auf Berichtigung: Der Geschädigte hat das Recht, gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung seiner beim Verantwortlichen gespeicherten personenbezogenen Daten zu verlangen.
    
    4. Recht auf Löschung und Vergessenwerden: Der Geschädigte hat das Recht, gemäß Art. 17 DSGVO die Löschung seiner beim Verantwortlichen gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist.
    
    5. Recht auf Einschränkung: Der Geschädigte hat gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung seiner personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von ihm bestritten wird, die Verarbeitung unrechtmäßig ist, der Geschädigte aber deren Löschung ablehnt und der Verantwortliche die Daten nicht mehr benötigt, der Geschädigte jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigt oder der Geschädigte gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt hat.
    
    6. Recht auf Datenübertragbarkeit: Der Geschädigte hat gemäß Art. 20 DSGVO das Recht, seine personenbezogenen Daten, die er dem Verantwortlichen bereitgestellt hat, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen.
    
    7. Recht auf Beschwerde: Der Geschädigte hat das Recht sich gemäß Art. 77 DSGVO bei einer Aufsichtsbehörde beschweren. Für den Verantwortlichen ist folgende Behörde zuständig:
    
    Der Landesbeauftragte für den Datenschutz und die Informationsfreiheit Baden-Württemberg Postfach 10 29 32, 70025 Stuttgart, Tel.: 0711/615541-0, FAX: 0711/615541-15.
    
    8.  Widerspruchsrecht: Sofern die personenbezogenen Daten des Geschädigten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, hat der Geschädigte das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung seiner personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus der besonderen Situation des Geschädigten ergeben.  Für die Geltendmachung des Widerspruchsrechts genügt eine E-Mail an den Verantwortlichen: info@florianplatau.de
   `;

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            filter: "brightness(20%) contrast(80%)",
          }}
        ></div>
        <MKTypography
          color="white"
          variant="h8"
          sx={{
            fontFamily: "'Playfair Display', serif",
            fontSize: "1.5rem",
            position: "absolute",
            top: "1rem",
            left: "1rem",
            zIndex: 1,
          }}
        >
          Florian Platau{" "}
        </MKTypography>
      </div>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "10vh", // Ändere auf 100vh für Vollbildhöhe
        }}
      >
        <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
          <MKTypography
            variant="h8"
            color="white"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontSize: { xs: "2.5rem", sm: "2.5rem", md: "5.5rem" },
              position: "absolute",
              top: { xs: "25%", sm: "-700%", lg: "-700%" },
              left: "50%",
              transform: "translateX(-50%)",
              textAlign: "center",
              zIndex: 2,
            }}
          >
            Python Software Engineer
          </MKTypography>
          <Button
            onClick={scrollToBottom}
            sx={{
              position: "absolute",
              top: { xs: "75%", sm: "-150%", lg: "-150%" }, // Anpassen der Button-Position basierend auf der Bildschirmgröße
              left: "50%",
              transform: "translateX(-50%)",
              borderRadius: "50%",
              width: "3.5rem",
              height: "3.5rem",
              backgroundColor: "transparent",
              border: "2px solid #C0C0C0",
              color: "#FFFFFF",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              "&:hover": {
                borderColor: "#FFFFFF",
                color: "#FFFFFF",
              },
            }}
          >
            <FaArrowDown style={{ color: "#FFFFFF" }} />
          </Button>
        </Grid>
      </Container>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div ref={scrollRef} />
        <UeberMich />
        <Information />
        <Testimonials />
        <Contact />
      </Card>
      <Container sx={{ display: "flex", justifyContent: "left", mt: 4, mb: 2 }}>
        <Button
          component="a"
          href="https://www.linkedin.com/in/florian-platau/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon style={{ color: "#7B809A", fontSize: 80 }} />
        </Button>
        <Button onClick={handleImpressumClick} variant="text" color="black">
          Impressum
        </Button>
        <Button onClick={handleDatenschutzClick} variant="text" color="black">
          Datenschutzerklärung
        </Button>
      </Container>
      <SimplePopup open={impressumOpen} onClose={handleClose} content={impressumContent} />
      <SimplePopup open={datenschutzOpen} onClose={handleClose} content={datenschutzContent} />
    </>
  );
}

export default Presentation;
