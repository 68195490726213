/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography
            variant="h8"
            color="text"
            mb={4}
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontSize: "1.5rem",
            }}
          >
            Seit 2020 arbeite ich als Softwareentwickler und entwickle maßgeschneiderte
            Softwarelösungen, die Unternehmen helfen, ihre Prozesse zu optimieren und effizienter zu
            gestalten.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
